import type { LoaderFunctionArgs, MetaFunction } from '@remix-run/node';
import {
  isRouteErrorResponse,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useRouteError,
} from '@remix-run/react';

import { captureRemixErrorBoundaryError, withSentry } from '@sentry/remix';
import { useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import { redirect, typedjson } from 'remix-typedjson';
import {
  Container,
  Navbar,
  PatchLimitExceededAlert,
  ShorebirdLogo,
} from '~/components';
import { isAuthenticated } from '~/services/auth.server';
import { ENV, getEnv } from '~/services/env.server';
import {
  AccountUsage,
  OrganizationMembership,
  OrganizationType,
  User,
  ServerError,
} from '@repo/shorebird-api';
import shorebird from '~/services/shorebird.server';
import { getToast, ToastNotification } from '~/services/toast.server';
import {
  reportError,
  showErrorToast,
  showSuccessToast,
  showWarningToast,
  useRootLoaderData,
} from '~/utils';
import './tailwind.css';

export const meta: MetaFunction = () => {
  const title = 'Shorebird Console';
  const description = 'Code Push for Flutter.';
  const image = '/open-graph.png';
  const url = 'https://console.shorebird.dev';

  return [
    { title: title },
    { name: 'description', content: description },
    {
      tagName: 'link',
      rel: 'apple-touch-icon',
      href: '/apple-touch-icon.png',
    },
    { property: 'twitter:card', content: 'summary' },
    { property: 'twitter:description', content: description },
    { property: 'twitter:image', content: image },
    { property: 'og:type', content: 'website' },
    { property: 'og:site_name', content: title },
    { property: 'og:title', content: title },
    { property: 'og:description', content: description },
    { property: 'og:image', content: image },
    { property: 'og:url', content: url },
  ];
};

/// This is the root loader for the entire application. Data loaded here is
/// available to all routes via useOptional and useRequired hooks.
export interface RootLoaderData {
  env: ENV;
  user?: User;
  organizations?: OrganizationMembership[];
  activeOrganization?: OrganizationMembership;
  usage?: AccountUsage;
  toastNotification?: ToastNotification;
  isPatchLimitExceeded?: boolean;
}

// Map of all server error codes to their respective redirect paths.
const redirects: { [key: string]: string } = {
  tos_acceptance_required: '/terms',
  user_auth_jwt_issuer_mismatch: '/auth/invalid-login-method',
};

export async function loader({ request }: LoaderFunctionArgs) {
  const env = getEnv();

  // Explicitly handle these routes to avoid an infinite redirect loop.
  const unauthenticatedPaths = [
    '/login',
    '/terms',
    '/auth/invalid-login-method',
  ];
  const url = new URL(request.url);
  if (unauthenticatedPaths.includes(url.pathname)) {
    return typedjson<RootLoaderData>({
      env,
    });
  }

  // If path name matches /orgs/:id/* extract the org id
  const organizationIdMatch = url.pathname.match(/\/orgs\/(\d+)/);
  const activeOrganizationId = organizationIdMatch
    ? +organizationIdMatch[1]
    : undefined;

  try {
    const credentials = await isAuthenticated({ request });
    const user = await shorebird.getUser({
      token: credentials.idToken,
    });
    const [organizations, toastNotification, isPatchLimitExceeded] =
      await Promise.all([
        shorebird.getOrganizationMemberships({
          token: credentials.idToken,
        }),
        getToast(request),
        shorebird.getPatchLimitExceeded({
          token: credentials.idToken,
        }),
      ]);
    const activeOrganization =
      organizations.find((o) => o.organization.id === activeOrganizationId) ??
      organizations.find(
        (o) => o.organization.type === OrganizationType.personal,
      );

    return typedjson<RootLoaderData>({
      env,
      user,
      organizations,
      toastNotification,
      activeOrganization,
      isPatchLimitExceeded,
    });
  } catch (error) {
    if (error instanceof ServerError && error.code in redirects) {
      return redirect(redirects[error.code]);
    }
    throw error;
  }
}

export function ErrorBoundary({ error }: { error: unknown }) {
  const routeError = useRouteError();
  const is404 = isRouteErrorResponse(routeError) && routeError?.status === 404;

  reportError(error, 'remix.client');

  if (is404) return <NotFound />;

  captureRemixErrorBoundaryError(error);

  return <InternalError />;
}

export function Layout({ children }: { children: React.ReactNode }) {
  const data = useRootLoaderData();
  return (
    <html lang="en" data-theme="business">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <script
          defer
          data-domain="console.shorebird.dev"
          src="https://plausible.io/js/script.tagged-events.js"
        ></script>
        <Meta />
        <Links />
      </head>
      <body>
        {children}
        <ScrollRestoration />
        <script
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(data?.env)}`,
          }}
        />
        <Scripts />
      </body>
    </html>
  );
}

function App() {
  const {
    activeOrganization,
    organizations,
    toastNotification,
    user,
    isPatchLimitExceeded,
  } = useRootLoaderData();
  useEffect(() => {
    if (!toastNotification) return;
    if (toastNotification.type === 'success') {
      showSuccessToast(toastNotification.message);
    }
    if (toastNotification.type === 'warning') {
      showWarningToast(toastNotification.message);
    }
    if (toastNotification.type === 'error') {
      showErrorToast(toastNotification.message);
    }
  }, [toastNotification]);

  return (
    <>
      {isPatchLimitExceeded && <PatchLimitExceededAlert />}
      <Navbar
        user={user}
        organizations={organizations}
        activeOrganization={activeOrganization}
      />
      <div className="h-24"></div>
      <Container>
        <Outlet />
        <Toaster />
      </Container>
    </>
  );
}

export default withSentry(App);

function NotFound() {
  return (
    <html lang="en" data-theme="business">
      <head>
        <title>404 | Shorebird</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body>
        <div className="flex h-screen flex-col items-center justify-center gap-4">
          <ShorebirdLogo className="h-48 w-48" />
          <h1 className="text-center text-3xl font-bold">
            Oh no!
            <br />
            This page does not exist!
          </h1>
          <p className="text-center text-lg leading-loose">
            The page you are looking for does not exist. Please check your URL
            for typos.
          </p>
        </div>
        <Scripts />
      </body>
    </html>
  );
}

function InternalError() {
  return (
    <html lang="en" data-theme="business">
      <head>
        <title>Oops! | Shorebird</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body>
        <div className="flex h-screen flex-col items-center justify-center gap-4">
          <ShorebirdLogo className="h-48 w-48" />
          <h1 className="text-center text-3xl font-bold">
            Oops!
            <br />
            Something went wrong.
          </h1>
          <p className="text-center text-lg leading-loose">
            An error occurred while rendering this page.
            <br />
            Try refreshing the page and if the problem persists, please{' '}
            <a className="link" href="mailto:contact@shorebird.dev">
              contact us
            </a>
            .
          </p>
        </div>
        <Scripts />
      </body>
    </html>
  );
}
