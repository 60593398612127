/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Form, useLocation } from '@remix-run/react';
import { ChevronDown, ChevronUp, CircleCheck, Plus } from 'lucide-react';
import { useRef, useState } from 'react';
import { OrganizationAvatar } from '~/components';
import {
  Organization,
  OrganizationMembership,
  OrganizationRole,
  OrganizationType,
  User,
} from '@repo/shorebird-api';
import { useLoseFocus } from '~/utils';
import { Badge } from '@repo/ui/components';

interface OrganizationsDropdownProps {
  user: User;
  organizations?: OrganizationMembership[];
  activeOrganization?: OrganizationMembership;
}

export const OrganizationsDropdown = (props: OrganizationsDropdownProps) => {
  const { organizations, activeOrganization } = props;
  const [isExpanded, setIsExpanded] = useState(false);
  const dropdownRef = useRef(null);
  useLoseFocus(dropdownRef, () => {
    setIsExpanded(false);
  });
  const location = useLocation();
  const isSettingsActive = location.pathname.endsWith('/settings');
  const myPersonalOrganization = organizations?.find(
    (o) =>
      o.organization.type === OrganizationType.personal &&
      o.role === OrganizationRole.owner,
  )?.organization;
  const isMyPersonalOrganization =
    activeOrganization?.organization.id === myPersonalOrganization?.id;
  const otherOrganizations = organizations?.filter(
    (o) => o.organization.id !== myPersonalOrganization?.id,
  );

  return (
    <div className="min-w-70 mx-auto max-w-fit">
      <button
        className="border-base-content/10 hover:border-accent flex w-full items-center justify-between rounded-md border px-6 py-2 shadow-sm focus:outline-none"
        onClick={() => setIsExpanded(!isExpanded)}
        aria-expanded={isExpanded}
        aria-haspopup="true"
      >
        {activeOrganization && (
          <OrganizationTile
            key={activeOrganization.organization.id}
            organization={activeOrganization.organization}
            isActive={false}
            role={activeOrganization.role}
            isPersonal={isMyPersonalOrganization}
          />
        )}
        <div className="w-2"></div>
        {isExpanded ? (
          <ChevronUp className="h-5 w-5 text-gray-400" />
        ) : (
          <ChevronDown className="h-5 w-5 text-gray-400" />
        )}
      </button>

      {isExpanded && (
        <div
          ref={dropdownRef}
          className="border-base-content/10 hover:border-accent z-100 bg-base-200 shadow-base-300/40 absolute left-20 right-0 top-full z-10 mt-1 max-w-fit rounded-md border shadow-lg transition-all duration-200 ease-in-out"
        >
          <div
            className="border-base-content/10 hover:bg-base-content/15 cursor-pointer border-b p-4"
            onClick={() => {
              window.location.href = '/';
            }}
          >
            {myPersonalOrganization && (
              <OrganizationTile
                key={myPersonalOrganization.id}
                organization={myPersonalOrganization}
                isActive={isMyPersonalOrganization}
                role={OrganizationRole.owner}
                isPersonal={true}
              />
            )}
          </div>
          <div className="border-base-content/10 p-4">
            <div className="flex items-center justify-between">
              <span className="font-light text-gray-400">Organizations</span>
              <Form reloadDocument method="post" action="/orgs/create">
                <button
                  className="text-accent hover:text-accent-focus rounded-full p-1 focus:outline-none"
                  aria-label="Add organization"
                  type="submit"
                >
                  <Plus className="h-5 w-5" />
                </button>
              </Form>
            </div>
          </div>
          {otherOrganizations?.map((o) => (
            <div
              key={o.organization.id}
              className="hover:bg-base-content/15 cursor-pointer p-4"
              onClick={() => {
                window.location.href = `/orgs/${o.organization.id}/${
                  isSettingsActive ? 'settings' : 'apps'
                }`;
              }}
            >
              <OrganizationTile
                key={o.organization.id}
                organization={o.organization}
                role={o.role}
                isActive={
                  o.organization.id === activeOrganization?.organization.id
                }
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

function OrganizationTile({
  organization,
  isActive,
  role,
  isPersonal,
}: {
  organization: Organization;
  isActive: boolean;
  role: OrganizationRole;
  isPersonal?: boolean;
}) {
  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center space-x-3">
        <div className="flex h-8 w-8 items-center justify-center rounded-md">
          <OrganizationAvatar
            className="flex-0 h-8 w-8"
            organization={organization}
            isPersonal={isPersonal}
          />
        </div>
        <p className="flex-1 text-left font-medium">{organization.name}</p>
        {!isPersonal && (
          <div className="shrink">
            <Badge
              className="bg-base-content/10 mr-3 border-none px-2 py-1"
              variant={'default'}
            >
              {role.toString() === 'none' ? 'guest' : role}
            </Badge>
          </div>
        )}
      </div>
      <div className="shrink">
        {isActive && <CircleCheck className="ml-2 text-accent h-5 w-5" />}
      </div>
    </div>
  );
}
