/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import { OrganizationsDropdown, ShorebirdLogo, UserAvatar } from '~/components';
import { LogOutIcon, UserCircleIcon } from 'lucide-react';
import { Form, useLocation } from '@remix-run/react';
import {
  OrganizationRole,
  OrganizationType,
  type OrganizationMembership,
  type User,
} from '@repo/shorebird-api';

interface Props {
  user?: User;
  organizations?: OrganizationMembership[];
  activeOrganization?: OrganizationMembership;
}

export const Navbar = (props: Props) => {
  const location = useLocation();
  const isSettingsActive = location.pathname.endsWith('/settings');
  const isAccountActive = location.pathname === '/account';
  const isAppsActive =
    location.pathname.includes('/apps') || location.pathname === '/';
  const { user, organizations, activeOrganization } = props;
  const isMyPersonalOrganization =
    activeOrganization?.organization.type === OrganizationType.personal &&
    activeOrganization?.role === OrganizationRole.owner;
  const canViewAccount = isMyPersonalOrganization;
  const canViewSettings =
    !isMyPersonalOrganization &&
    (activeOrganization?.role === OrganizationRole.owner ||
      activeOrganization?.role === OrganizationRole.admin);
  return (
    <div className="navbar bg-base-100 lg:bg-base-200/20 fixed shadow-md lg:shadow-none lg:backdrop-blur-xl z-50">
      <div className="flex w-full flex-row items-center justify-between">
        <div className="flex items-center">
          <a
            href="/"
            className="btn-ghost btn rounded-md text-xl normal-case transition duration-300 hover:scale-110 hover:bg-transparent"
          >
            <ShorebirdLogo />
          </a>
          {user && (
            <OrganizationsDropdown
              user={user}
              organizations={organizations}
              activeOrganization={activeOrganization}
            />
          )}
          <div className="ml-4 flex flex-row gap-4">
            {user && (
              <a
                href={
                  isMyPersonalOrganization
                    ? '/'
                    : `/orgs/${activeOrganization?.organization.id}/apps`
                }
                className={`link link-hover decoration-accent normal-case underline-offset-2 ${
                  isAppsActive ? 'underline' : ''
                } hidden sm:flex`}
              >
                Apps
              </a>
            )}
            {user && canViewAccount && (
              <a
                href="/account"
                className={`link link-hover decoration-accent normal-case underline-offset-2 ${
                  isAccountActive ? 'underline' : ''
                } hidden sm:flex`}
              >
                Account
              </a>
            )}
            {user && canViewSettings && (
              <a
                href={`/orgs/${activeOrganization?.organization.id}/settings`}
                className={`link link-hover decoration-accent normal-case underline-offset-2 ${
                  isSettingsActive ? 'underline' : ''
                } hidden sm:flex`}
              >
                Settings
              </a>
            )}
          </div>
        </div>
        <div className="flex items-center">
          <span className="hidden sm:flex">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://docs.shorebird.dev"
              className="btn-ghost btn-outline btn-sm btn rounded-md normal-case"
            >
              Docs
            </a>
          </span>
          <div className="w-2"></div>
          {user && <DropdownMenu user={user} />}
        </div>
      </div>
    </div>
  );
};

function DropdownMenu({ user }: { user: User }) {
  return (
    <div className="dropdown-end dropdown">
      <label tabIndex={0} className="btn-ghost btn-circle avatar btn">
        <div className="w-10 rounded-full">
          {<UserAvatar className="h-8 w-8" displayName={user.email} />}
        </div>
      </label>
      <ul
        tabIndex={0}
        className="dropdown-content menu w-72 justify-center p-4"
      >
        <div className="flex justify-center">
          <UserAvatar displayName={user.email} />
        </div>
        <div className="my-2"></div>
        <div className="flex justify-center">
          {user.displayName && <p>{user.displayName}</p>}
        </div>
        <div className="px-4 text-center text-sm">{user.email} </div>
        <div className="divider"></div>
        <li>
          <a
            role="menuitem"
            tabIndex={-1}
            id="menu-item-1"
            href="/account"
            className="flex w-full items-center justify-between"
            aria-label="Account"
          >
            <span>Account</span>
            <UserCircleIcon />
          </a>
        </li>
        <li>
          <Form className="flex" action="/logout" method="post">
            <button
              role="menuitem"
              tabIndex={-1}
              id="menu-item-2"
              type="submit"
              className="flex w-full items-center justify-between hover:text-red-500"
              aria-label="Logout"
            >
              <span>Logout</span>
              <LogOutIcon />
            </button>
          </Form>
        </li>
      </ul>
    </div>
  );
}
